.container {
	width: fit-content;
	height: fit-content;
	border-radius: 20px;
	background: #090041;
	padding: 76px 0px;
	width: 117%;
    height: 520px;
    margin-right: -51px;
}

.container_gray{
    background: #AFC0D9;
    width: 100%;
    margin-right: 0px;
}
.keypad {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 46px;
}

.key {
	width: 94.9px;
	height: 94.9px;
	background-color: #283760;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-family: Roboto;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.key:hover {
	background-color: #f8a801;
	transition: background-color 200ms linear;
}


.cancel{
	background-color: #c93d4e;
    height: 98px;
    border-radius: 12px;
    padding-top: 17px;
	width: 105px;
}

.approve{
	background-color: #4abb92;
    height: 98px;
    border-radius: 12px;
    padding-top: 17px;
	width: 105px;
}

.btn_letter{
    background-color: white;
    font-size: 38px;
    border-radius: 10px;
    width: 59px;
    margin-left: 5px;
    box-shadow: 0px 2px 0px #0000005e;
    height: 60px;
    line-height: 60px;
}

.line{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 90%;
    margin-right: 5%;
    gap: 5px;
}

.btn_letter_1{
    background-color: white;
    font-size: 38px;
    border-radius: 10px;
    width: 60px;
    margin-left: 5px;
    box-shadow: 0px 2px 0px #0000005e;
    height: 60px;
    line-height: 60px;
}

.backspace{
	background-image: url(../../../assets/cancel.png);
	background-size: 62%;
    background-repeat: no-repeat;
    background-position: 12px 7px;
    background-color: white;
    border-radius: 10px;
    width: 68px;
    margin-left: 5px;
    box-shadow: 0px 2px 0px #0000005e;
}

.long_space{
    background-color: white;
    font-size: 38px;
    border-radius: 10px;
    width: 100%;
    margin-left: 5px;
    box-shadow: 0px 2px 0px #0000005e;
    margin-top: 5px;
}

.long_space:hover , .btn_letter_1:hover, .btn_letter:hover{
	background-color: #f8a801;
	transition: background-color 200ms linear;
}