.stack-row {
  min-height: 166px;
  max-height: 166px;
  color: #283760;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 0px;
  border-bottom: 3px dashed #DADADA;
  padding-bottom: 5px;
  align-items: center;
}

.stack-row-col-first {
  min-width: 109px;
  width: 109px;
  text-align: right;
  padding-top: 20px;
}

.stack-row-col-long {
  min-width: 260px;
  width: 260px;
  text-align: right;
  padding-top: 20px;
  margin-right: 40px;
}

.stack-row-col {
  text-align: right;
  min-width: 200px;
  padding-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
}

.stack-row-count {
  text-align: right;
  min-width: 155px;
  padding-top: 20px;
  margin-right: 40px;
}

.stack-row-col-last {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 8px;
  padding-left: 16px;

  background-color: inherit;
  outline: none;
  border: none;
}

.stack-row-input {
  height: 91px;
  width: 222px;
  border: 1.45px #283760 solid;
  margin-right: 19px;
  margin-top: auto;
  margin-bottom: auto;

  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  line-height: 250%;
  letter-spacing: 0%;
  text-align: right;

  border-radius: 20px;
  padding: 20px;
  padding-bottom: 0px;
  box-sizing: border-box;

  color: rgb(40, 55, 96);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}
