.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  padding-top: 100px;
  margin: 0 auto;
	border-radius: 24px;
	background-color: white;
  width: 92%;
}

.currencies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;

}

.title {
  font-size: 60px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: #283760;
}

.button {
  border-color: "#fff";
}

.button-icon {
  width: 8px;
  height: 16px;
}

.buttons-container-currency{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 96px;
  margin-bottom: 31px;
  margin-top: 267px;
  justify-content: space-around;
  gap: 20px;
}

.main_cont{
  background-color: #090041;
}

.currency-box-container{
  width: 750px;
  height: 175px;
}

.smalltitle{
  font-size: 32px;
  position: relative;
  top: -6px;
}

.is_icon{
  position: relative;
  top: 13px;
}

.us_icon{
  position: relative;
  top: 3px;
  right: 19px;
  width: 39px;
}
.subtitle{
  color:#283760;
  font-size: 40px;
  
  font-weight: 500;
}

.subtitle_m{
  color: #283760;
  font-size: 40px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 30px;
}

.price_cont{
  display: flex;
  gap: 10px;
}

.subtitle_r{
  display: flex;
  flex-direction: column;
  width: 793px;
}

.subtitle_s{
  color: #283760;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 15px;
}

.subtitle_s_left{
  color: #283760;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 15px;
  justify-content: flex-end;
}
.subtitle_sm{
  color: #283760;
  font-size: 40px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px;
  
}

.up_down{
  position: absolute;
  top: 286px;
  left: 36%;
}

.small_subtitle{
  color: #283760;
  font-size: 24px;
  position: absolute;
  top: 185px;
}

.united_states{
  width: 31px;
  height: 31px;
  margin-top: 13px;
}

.subtitle_m_no_border{
  border: 0;
}

.currencies-container-gap{
  gap:60px
}