.layout {
	position: relative;
	width: 100vw;
	height: 100dvh;
	background-color: white;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}

.header {
	position: relative;
	height: 120px;
	overflow: hidden;
}

.logo {
	position: absolute;
	left: 0;
	width: 40%;
	height: 120px;
	max-width: 400px;
}

.logo > svg {
	height: fit-content;
}

.children {
	flex: 1 1 auto;
	/* padding: 100px 0; */
}

.children > div {
	height: fit-content;
	min-height: 100%;
}

.footer {
	color: #fff;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #090041;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
	gap: 35px;
	border-top: 1px solid white;
}

.contacts {
	display: flex;
	gap: 12px;
	align-items: center;
	justify-content: center;
	text-align: right;
	font-family: "Noto Sans Hebrew";
	font-style: normal;
	font-size: 24px;
	font-weight: 400;
}

.powerBy {
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 16.199px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	margin-bottom: 46px;
	margin-top: 40px;
	/* border-right: 1px solid white; */
    padding-right: 25px;
}
.settings_icon_cont{
	width: 40px;
    height: 40px;
    background-color: white;
    margin-top: 65px;
    padding-top: 10px;
    border-radius: 6px;
}
.stick {
	font-weight: 100;
}

.link {
	cursor: pointer;
	text-decoration: underline;
	text-underline-offset: 3px;
	text-decoration-thickness: 1px;
}
.PowerBy{
	width: 97px;
}