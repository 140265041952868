.currency-box-icon {
  width: 69px;
  height: 69px;
}

.currency-box-container {
  min-width: 750px;
  min-height: 175px;
  padding: 100px 120px;
  box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -webkit-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -moz-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: fit-content;
  border: none;
  background-color: #fff;
}


.currency_text{
  font-size: 53px;
  color:#283760;
  font-weight: bold;
  margin-right: 15px;
}