.WelcomeScreenMatah{
   
    background-color: #090041;
    /* padding-top: 30px; */
    min-height: 92% !important;

.head_title{
    color: white;
    font-size: 68px;
}

.header_cont{
    display: flex ;
    justify-content: center;
    gap: 70px;
    border-bottom: 1px solid white;
}

.smart_kiosk{
    height: 100px;
    margin-top: 50px;
}

.mid_title{
    font-size: 50px;
    color: #283760;
    text-align: center;
    font-weight: bold;
    padding-top: 32px;
    width: 84%;
    margin: 0 auto;
    margin-bottom: 60px;
}

.action_btn{
    width: 269px;
    height: 116px;
    box-shadow: 1px 1px 30px white;
    border-radius: 20px;
    background-color: #090041;
    margin: 0 auto;
    margin-bottom: 9px;
    cursor: pointer;
}

.action_btn_cont_line{
    display: flex;
    justify-content: center;
    gap: 45px;
    margin-top: 37px;
    margin-bottom: 25px;
}

.action_btn_text{
    color: white;
    font-size: 27px;
    font-weight: bold;
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

.action_btn_text_small{
    color: white;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

.ict{
    margin-top: 23px;
}

.action_btn_cont{
    width: 262px;
}

.WelcomeScreenSecond_first_part{
    background-color: white;
    height:1022px;
    width: 92%;
    margin: 0 auto;
    border-radius: 24px;
}

.secong_screen_title{
    font-size: 35px;
    color: #283760;
    width: 670px;
    text-align: right;
}

.secong_screen_title_cont{
    display: flex;
    gap: 30px;
    margin-top: 315px;
    justify-content: center;
    margin-right: 178px;
}

.start_buy_btn{
    background-color: #283760;
    border-radius: 10px;
    border: 0;
    width: 581px;
    height: 116px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-top: 78px;
    text-align: right;
    padding-right: 65px;
    background-image: url(../../assets/left.png);
    background-repeat: no-repeat;
    background-position: 45px 45px;
    cursor: pointer;
}


.WelcomeScreenSecond_sec_part {
    background-color: white;
    height: 635px;
    width: 92%;
    margin: 0 auto;
    border-radius: 24px;
    margin-top: 50px;
    margin-bottom: 60px;
}

.site_address{
    color:#FC9605;
    font-size: 28px;
    font-weight: bold;
    margin-top: 15px;
}

.images_phones_cont{
    display: flex;
    margin-top: 45px;
    gap: 5px;
    
}

.left_arrow_orange{
    height: 56px;
    margin-top: 192px;
}

.qrcode_icon_cont{
    margin-top: 63px;
}


.small_title{
    color:#283760;
    font-size: 24px;
    width: 250px;
}

.small_title_cont{
    border-bottom: 3px dashed #DADADA;
    display: flex;
    padding-bottom: 13px;
    padding-right: 80px;
    text-align: right;
    padding-top: 10px;
    justify-content: center;
}

.b_title{
    font-size: 40px;
    align-self: center;
    font-weight: bold;
    color:#283760;
    direction: ltr;
    width: 250px;
}

.euro_icon{
    width: 55px;
}

.b_title_one{
    text-align: left;
    margin-left: 15px;
}
}
