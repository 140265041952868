.container {
	height: 100%;
	width: 92%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
	border-radius: 24px;
	background-color: white;
}

.content {
	width: 600px;
}

.title {
	color: var(--blue, #283760);
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
	margin: 0 0 50px;
	padding-top: 25px;
}

.phoneInput {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 64px;
}

.buttons {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	width: min(100%, 700px);
	margin-top: 60px;
	margin-bottom: 170px;
}

.arrowRight {
	transform: rotate(180deg);
}

.arrowRight > svg > g > path {
	stroke: #283760;
}

.buttons > button {
	border: 0px !important;
}

.subtitle{
	font-size: 32px;
    font-weight: bold;
    margin-bottom: 26px;
}

.register_btn_option{
    height: 240px;
    width: 576px;
    font-size: 48px;
    background-color: transparent;
    border: 0;
    box-shadow: 1px 1px 27px #28376057;
    color: #283760;
    border-radius: 24px;
    margin-bottom: 50px;
	position: relative;
	line-height: 300px;
}

.register_btn_option_icon{
	position: absolute;
    top: 50px;
    left: 260px;
}

.register_btn_option_phone{
	position: absolute;
    top: 50px;
	left: 272px;
}

.main_cont{
	background-color: #090041;
}