.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  padding-top: 100px;
  margin: 0 auto;
	border-radius: 24px;
	background-color: white;
  width: 92%;
}

.currencies-container-t {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  width: 100%;
}

.title {
  font-size: 60px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: #283760;
}

.button {
  border-color: "#fff";
}

.button-icon {
  width: 8px;
  height: 16px;
}

.buttons-container-currency-total{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 96px;
    margin-bottom: 31px;
    margin-top: 160px;
    justify-content: space-between;
  
}

.main_cont{
  background-color: #090041;
}

.currency-box-container{
  width: 750px;
  height: 175px;
}

.smalltitle{
  font-size: 32px;
  position: relative;
  top: -6px;
}

.is_icon{
  position: relative;
  top: 13px;
}

.us_icon{
  position: relative;
  top: 13px;
  right:10px
}
.subtitle{
  color:#283760;
  font-size: 40px;
  
  font-weight: 500;
}


.price_cont{
  display: flex;
  gap: 10px;
}

.price_cont_g{
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.subtitle_r{
  display: flex;
  flex-direction: column;
}

.subtitle_s{
  color: #283760;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 15px;
}

.subtitle_sm{
  color: #283760;
  font-size: 40px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px;
  
}

.small_rate{
  font-size: 20px;
}

.p_cont{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
}

.blue_bg{
  background-color: #F6F8FC;
}


.title_b {
    margin-bottom: 25px;
}

.subtitle_m_m{
  color: #283760;
  font-size: 40px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 35px;
  padding-top: 35px;
  gap:15px
}
