.WelcomeScreenSecond {
  background-color: #090041;
  min-height: 92% !important;

  .head_title {
    color: white;
    font-size: 68px;
  }

  .header_cont {
    display: flex;
    justify-content: center;
    gap: 70px;
    border-bottom: 1px solid white;
  }

  .smart_kiosk {
    height: 100px;
    margin-top: 50px;
  }

  .mid_title {
    width: 84%;
    font-family: Noto Sans Hebrew;
    font-weight: 800;
    font-size: 60px;
    line-height: 109.00000000000001%;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    padding-top: 32px;
    margin: 0 auto;
    margin-bottom: 45px;
    color: #283760;
  }

  .action_btn {
    width: 269px;
    height: 116px;
    box-shadow: 1px 1px 30px white;
    border-radius: 20px;
    background-color: #090041;
    margin: 0 auto;
    margin-bottom: 9px;
    cursor: pointer;
  }

  .action_btn_cont_line {
    display: flex;
    justify-content: center;
    gap: 45px;
    margin-top: 37px;
    margin-bottom: 25px;
  }

  .action_btn_text {
    color: white;
    font-size: 27px;
    font-weight: bold;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  .action_btn_text_small {
    color: white;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  .ict {
    margin-top: 23px;
  }

  .action_btn_cont {
    width: 262px;
  }

  .WelcomeScreenSecond_first_part {
    background-color: white;
    height: 838px;
    width: 92%;
    margin: 0 auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .secong_screen_title {
    font-size: 44px;
    color: #283760;
    width: 670px;
    text-align: right;
  }

  .secong_screen_title_cont {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
  }

  .start_buy_btn {
    background-color: #FC9605;
    border-radius: 10px;
    border: 0;
    width: 581px;
    height: 116px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-top: 78px;
    text-align: right;
    padding-right: 65px;
    background-image: url(../../assets/left.png);
    background-repeat: no-repeat;
    background-position: 45px 45px;
    cursor: pointer;
  }


  .WelcomeScreenSecond_sec_part {
    background-color: white;
    height: 635px;
    width: 92%;
    margin: 0 auto;
    border-radius: 24px;
    margin-top: 50px;
    margin-bottom: 60px;

    .mid_title {
      font-family: Noto Sans Hebrew;
      font-weight: 700;
      font-size: 40px;
      line-height: 109.00000000000001%;
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
      color: #283760;

      width: 84%;
      padding-top: 32px;
      margin: 0 auto;
      margin-bottom: 45px;
    }
  }

  .site_address {
    color: #FC9605;
    font-size: 28px;
    font-weight: bold;
    margin-top: 15px;
  }

  .images_phones_cont {
    display: flex;
    margin-top: 45px;
    gap: 5px;
    justify-content: center;
  }

  .left_arrow_orange {
    height: 56px;
    margin-top: 192px;
  }

  .qrcode_icon_cont {
    margin-top: 63px;
  }

  .rates-titles-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;

    .rates-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 325px;

      img {
        height: 56px;
        width: 56px;
      }

      .rates-title {
        margin: 0;
        margin-top: 4px;
        font-family: Noto Sans Hebrew;
        font-weight: 400;
        font-size: 40px;
        line-height: 109%;
        letter-spacing: 0%;
        text-align: center;
        color: #283760;
      }
    }
  }

  .rates-table {
    width: 578px;
    margin-top: 20px;
    border-collapse: collapse;

    .rates-subtitle {
      font-family: Noto Sans Hebrew;
      font-weight: 500;
      font-size: 24px;
      line-height: 109%;
      letter-spacing: 0%;
      text-align: right;
      vertical-align: middle;
      color: #283760;
      padding-bottom: 8px;
    }

    tr {
      border-bottom: 3px dashed #DADADA;
    }

    td {
      font-family: Noto Sans Hebrew;
      font-weight: 700;
      font-size: 40px;
      line-height: 109%;
      letter-spacing: 0%;
      text-align: right;
      vertical-align: middle;
      color: #283760;
      padding: 12px 0;

      .sign {
        font-size: 30px;
      }

      .currency-preview {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 16px;
      }

      .currency-img {
        width: 55px;
        height: 55px;
      }
    }

  }
}
