.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
	border: 1px solid red;
    border-radius: 17px;
}

.phoneNumber {
	width: 396px;
	height: 111px;
	flex-shrink: 0;
	color: #283760;
	background: #f2f4f8;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 109%;
	margin: 18px auto 50px;
}

.contact {
	color: var(--blue, #283760);
	text-align: right;
	font-family: "Noto Sans Hebrew";
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 109%;
	margin-top: 18px;
}

.time {
	color: var(--blue, #283760);
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 109%;
	white-space: pre-line;
	margin-top: 15px;
}

.try_again{

	width: 262px;
    height: 97px;
    margin-top: 41px;
    font-size: 40px;
    background-color: #283760;
    color: white;
    font-family: "Noto Sans Hebrew";
	background-image: url(../../../assets/left.png);
    background-repeat: no-repeat;
    padding-left: 50px;
    background-position: 35px;
    font-weight: 700;
    background-size: 10%;
    border-radius: 10px;
}
.err_img{
	margin-bottom: 70px;
}