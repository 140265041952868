.title {
    font-size: 60px;
    font-weight: 800;
    color: #283760;
    margin: 0;
}

.checkout-header {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    gap: 20px;
    padding-bottom: 20px;
}

.message-text {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    color: #283760;
}

.contact-info-container {
    display: flex;

    gap: 10px;
}

.checkout-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 50px;
}

.bottom-container {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 27px;
    color: #283760;
    gap: 10px;
    position: relative;
    top: 14px;
    width: 115%;
    margin-top: 43px;
    margin-bottom: 33px;
}

.checkout-main-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.main-container{
	background-color: #090041;
}

.container {
	height: 100%;
	width: 92%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
	border-radius: 24px;
	background-color: white;
}
