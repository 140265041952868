.container {
	width: fit-content;
	height: fit-content;
	border-radius: 20px;
	/* background: #d5dfed; */
	padding: 76px 112px;
	border: 1px solid #AFC0D9;
}

.container_white{
	width: fit-content;
	height: fit-content;
	border-radius: 20px;
	/* background: #d5dfed; */
	padding: 76px 112px;
	border: 1px solid #AFC0D9;
	background-color: white;
}

.keypad {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 46px;
}

.key {
	width: 94.9px;
	height: 94.9px;
	background-color: #283760;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-family: Roboto;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.key:hover {
	background-color: #f8a801;
	transition: background-color 200ms linear;
}


.cancel{
	background-color: #c93d4e;
    height: 98px;
    border-radius: 12px;
    padding-top: 17px;
	width: 105px;
}

.approve{
	background-color: #4abb92;
    height: 98px;
    border-radius: 12px;
    padding-top: 17px;
	width: 105px;
}