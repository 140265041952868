.title {
  font-size: 60px;
  font-weight: 800;
  color: #283760;
}

.total-container {
  background-color: #283760;
  height: 120px;
  width: 485px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 30px;
}

.credit-options-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.option-icon {
  width: 60%;
  height: 60%;
}

.options-title {
  font-size: 32px;
  font-weight: 600;
  color: #283760;
  margin-top: 60px;
  margin-bottom: 46px;
}

.bottom-text {
  max-width: 500px;
}

.payment-bottom-container {
  display: flex;
  padding: 0px 80px 15px 80px;
  gap: 19px;
  width: 630px;
  margin-bottom: 28px;
  border-radius: 12px;
  border: 3px solid #f2f4f8;
  box-shadow: 1px 1px 25px #00000096;
}

.button-container {
    display: flex;
    justify-content: center;

      width: 100%;
      margin-bottom: 150px;
      margin-top: 85px;
}

.promotion-text {
  text-align: right;
}

.nfc-option {
  position: relative;
  left: 40px;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 0 auto;
	border-radius: 24px;
	background-color: white;
  width: 92%;
}

.option-container {
  display: flex;
  width: 300px;
  height: 360px;
  flex-direction: column;
  border-radius: 14px;
  background-color: #dfe6f1;
  border: 1px solid #dadada;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.total-container-text {
  color: #fff;
  font-weight: 500;
  font-size: 44px;
}

.option-text {
  font-size: 28px;
  font-weight: 700;
  color: #283760;
}


.main_cont{
	background-color: #090041;
}