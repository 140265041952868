.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__name {
    font-family: Noto Sans Hebrew;
    font-weight: 700;
    font-size: 50px;
    line-height: 36px;
    letter-spacing: 0%;
    text-align: center;
    color: white;
  }
}

.service {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  border: none;
  outline: none;
  width: 300px;
  box-sizing: border-box;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &__card {
    width: 269px;
    height: 116px;
    box-shadow: 1px 1px 30px white;
    border-radius: 20px;
    background-color: #090041;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    max-height: 76%;
    max-width: 80%;
  }

  &__name {
    width: 100%;
    max-width: 100%;
    font-family: Noto Sans Hebrew;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0%;
    text-align: center;
    color: white;
    margin: 0;
    margin-top: 20px;
    padding: 0 6px;
  }

  &__description {
    font-family: Noto Sans Hebrew;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;

    color: white;
    padding: 0 50px;
    margin: 0;
  }
}

.slider {
  &__contentWrapper {
    width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    flex-direction: row-reverse;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 16px;
  }

  &__content {
    display: flex;
    background-color: transparent;
    justify-content: end;
    width: 960px;
    min-height: 230px;
    gap: 20px;
  }

  ._arrow {
    margin-top: 43px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;

    img {
      height: 30px;
    }
  }

  &__arrowLeft {
    @extend ._arrow;
  }

  &__arrowRight {
    @extend ._arrow;

    transform: rotate(180deg);
  }

  &__dots {
    display: flex;
    gap: 20px;
    width: fit-content;
    justify-self: center;

    ._dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &__dot {
      @extend ._dot;
      background-color: white;
    }

    &__dotActive {
      @extend ._dot;
      background-color: #E22887;
    }
  }
}
