

.keypad {
	display: flex;
	flex-wrap: wrap;
	gap: 49px;
	margin-top: 69px;
	padding-left: 88px;
	max-width: 990px;
	margin: 0 auto;
	margin-top: 69px;
}

.key {
	width: 94.9px;
	height: 94.9px;
	background-color: #283760;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-family: Roboto;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cancel{
	background-color: #c93d4e;
    height: 98px;
    border-radius: 12px;
    padding-top: 17px;
	width: 105px;
}

.cancel_white{
    margin-left: 145px;
}

.cancel_white_icon{
    margin-right: 4px;
}
