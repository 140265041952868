.container {
	height: 100%;
	width: 92%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
	border-radius: 24px;
	background-color: white;
}

.content {
	width: 600px;
}

.title {
	color: var(--blue, #283760);
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
	margin: 0 0 50px;
	padding-top:25px;
}

.phoneInput {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 64px;
}

.buttons {
    box-sizing: border-box;
    display: flex;
    width: 700px;
    margin-top: 60px;
    gap: 0;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.arrowRight {
	transform: rotate(180deg);
}

.arrowRight > svg > g > path {
	stroke: #283760;
}

.buttons > button {
	border: 0px !important;
	font-size: 24px;
	height: 70px;
}

.subtitle{
	font-size: 32px;
}

.code_place{
    background-color: #F2F4F8;
    height: 130px;
    width: 522px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-direction: row-reverse;
    margin-top: 30px;
}

.buttons_bottom{
	margin-top: 350px;
	margin-bottom: 542px;
}

.phone_to_send{
	direction: ltr;
    font-size: 60px;
    margin-top: 28px;
}

.main_cont{
	background-color: #090041;
}